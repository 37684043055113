#home{
	#home-title{
		background-image: url('../../img/home-title-bg.svg');
		@apply bg-no-repeat bg-cover bg-bottom;
	}
	#home-single-image{
		& + section{
			@apply pt-[20px];
		}
	}
	@screen lg{
		#home-single-image{
			& + section{
				@apply pt-[60px];
			}
		}
	} 
}