.v-align-solo{
	@apply relative top-1/2;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.clamped{
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
	overflow: hidden;
}
.blurred{
	@apply blur-sm;
}
.header-fixed{
	@apply fixed top-0 left-0 w-full z-30 bg-background;
	.icon-socials{
		i{
			@apply lg:text-secondary;
		}
	}
	& + *{
		margin-top: $header-height;
	}
}
#agency{
	.header-fixed{
		& + * + *{
			margin-top: $header-height;
		}
	}
}
.background-overlay{
	@apply relative;
	&:after{
		content: "";
		@apply absolute left-0 top-0 w-full h-full z-[1];
		background-color: rgba(0, 0, 0, 0.2);
	}
	& > *{
		@apply z-[2];
	}
}
@screen xl {
	.header-fixed{
		@apply py-0 transition-all duration-200;
		img{
			@apply w-auto py-4 h-[105px] transition-all duration-200;
		}
		& + *{
			margin-top: #{$desktop-header-height};
		}
	}
	#agency{
		.header-fixed{
			& + * + *{
				@apply mt-0;
			}
		}
	}
}