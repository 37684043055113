@font-face {
  font-family: 'Prata';
  src: local('Prata'),
  local('prata'),
  url('/fonts/prata/Prata-Regular.eot');
  src: url('/fonts/prata/Prata-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/prata/Prata-Regular.woff') format('woff'),
  url('/fonts/prata/Prata-Regular.woff2') format('woff2'),
  url('/fonts/prata/Prata-Regular.ttf') format('truetype'),
  url('/fonts/prata/Prata-Regular.svg#Prata-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Light';
  src: local('Lato Light'),
  local('Lato-Light'),
  local('lato-thin'),
  url('/fonts/lato/Lato-Light.eot');
  src: url('/fonts/lato/Lato-Light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/lato/Lato-Light.woff') format('woff'),
  url('/fonts/lato/Lato-Light.woff2') format('woff2'),
  url('/fonts/lato/Lato-Light.ttf') format('truetype'),
  url('/fonts/lato/Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'),
  local('lato'),
  url('/fonts/lato/Lato-Regular.eot');
  src: url('/fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/lato/Lato-Regular.woff') format('woff'),
  url('/fonts/lato/Lato-Regular.woff2') format('woff2'),
  url('/fonts/lato/Lato-Regular.ttf') format('truetype'),
  url('/fonts/lato/Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DancingScript';
  src: local('DancingScript'),
  local('dancingscript'),
  url('/fonts/dancing-script/DancingScript-Regular.eot');
  src: url('/fonts/dancing-script/DancingScript-Regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/dancing-script/DancingScript-Regular.woff') format('woff'),
  url('/fonts/dancing-script/DancingScript-Regular.woff2') format('woff2'),
  url('/fonts/dancing-script/DancingScript-Regular.ttf') format('truetype'),
  url('/fonts/dancing-script/DancingScript-Regular.svg#DancingScript-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3g3rkq');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?3g3rkq#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?3g3rkq') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?3g3rkq') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?3g3rkq') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?3g3rkq##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline; 
  }
}
.icon-palette {
  &:before {
    content: $icon-palette; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-burger-menu {
  &:before {
    content: $icon-burger-menu; 
  }
}
.icon-check-outlined {
  &:before {
    content: $icon-check-outlined; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-holding-heart {
  &:before {
    content: $icon-holding-heart; 
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}

