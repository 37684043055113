header{
    @apply py-4 xl:py-7.5;
    .site-menu{
        @apply fixed top-0 left-0 h-full w-full translate-x-full transition-transform duration-200;
        &.opened{
            @apply translate-x-0;
        }
    }
}

@screen xl{
    header{
        .site-menu{
            @apply translate-x-0 relative h-auto w-auto;
            
        }
    }
}

