.block-testimonies{
	.custom-border-design{
		@apply relative;
		&:after,&:before{
			content: "";
			@apply absolute h-[70px] w-0.5 bg-white left-1/2 -ml-[1px];
		}
		&:after{
			@apply -top-[120px];
		}
		&:before{
			@apply -bottom-[120px];
		}
	}
}