.block-carousels{
	.owl-section{
		.owl-stage{
			.owl-item{
				@apply h-[400px];
				img, picture{ 
					@apply h-full;
				}
			}
		}
		.owl-dots{
			button{
				@apply bg-primary opacity-75;
				&.active{
					@apply bg-secondary;
				}
			}
		}
	}
	@screen lg{
		.owl-section{
			.owl-stage{
				.owl-item{
					@apply justify-center;
				}
			}
		}
	}
	@screen xl{
		.owl-section{
			.owl-stage{
				@apply h-[530px] items-center;
				.owl-item{
					@apply items-center scale-100 transition-all duration-300 h-[383px];
					img, picture{ 
						@apply h-auto transition-all duration-300;
					}
					&.active{
						&.center{
							@apply h-full justify-center;
							picture, img{
								@apply w-[380px];
							}
						}
					}
				}
			}
		}
	}
}

#projects{
	.block-carousels{
		@screen xl{
			.owl-section{
				.owl-stage{
					.owl-item{
						@apply justify-end scale-125;
						&.active{
							@apply justify-start;
							~ .active{
								@apply justify-end;
							}
							& > *{
								@apply relative -left-20;
							}
							&.center{
								& > *{
									@apply left-0;
								}
								transform: scale(1.65);
								picture, img{
									@apply w-full object-contain;
								}
								& + .active{
									& > *{
										left: inherit;
										@apply -right-20;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}