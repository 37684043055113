@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply overflow-x-hidden bg-white text-font-medium text-body;
  }
  h1, h2, h3, p {
    @apply m-0;
  }
  ol, ul {
    @apply list-none p-0 m-0;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
  }
  hr {
    @apply border-black;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a, a:active, a:hover, a:focus {
    @apply no-underline;
  }
  label {
    @apply mb-0;
  }
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes navbar-animate {
    0% {
      top: -80px;
    }
    100% {
      top: 0;
    }
  }
  @keyframes navbar-animate-back {
    0% {
      top: 0;
    }
    100% {
      top: -80px;
    }
  }
  .errors-container, .success-container {
    @apply hidden;
  }
  .invalid-feedback, .success-feedback {
    @apply text-base inline-block w-full text-body;
  }
  .success-feedback {
    @apply text-success;
  }
  .invalid-feedback {
    @apply text-error;
  }
  .loading:before {
    content: "";
    @apply absolute w-full h-full left-0 top-0 bg-white bg-opacity-50 rounded-lg z-10;
  }
  .loading:after {
    content: "";
    @apply absolute left-1/2 top-1/2 border-8 border-white rounded-full border-t-black border-b-black z-20;
    margin-top: -25px;
    margin-left: -25px;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
  .loading-no-opacity:before {
    @apply bg-white;
  }
  #site-container {
    @apply transition-all duration-300 relative z-10;
  }
  .container {
    @apply mx-auto px-4 md:px-6 lg:px-8 xl:px-12;
  }
  .grid {
    @apply gap-10;
  }
  section.shifted {
    @apply relative;
    top: -200px;
    height: calc(100% + 200px);
  }
  .landing-overlay {
    height: 280px;
  }
  .landing-overlay:before {
    content: "";
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0) 99%);
    @apply absolute top-0 left-0 w-full;
    height: 130px;
  }
  [class^=block-], [class*=" block-"] {
    @apply py-[40px];
  }
  @screen lg {
    [class^=block-], [class*=" block-"] {
      @apply py-[120px];
    }
    .landing-container, .landing-overlay {
      @apply top-0 flex items-center;
      height: 582px;
    }
    .landing-container:before, .landing-overlay:before {
      @apply content-none;
    }
  }
}
@font-face {
  font-family: "Prata";
  src: local("Prata"), local("prata"), url("/fonts/prata/Prata-Regular.eot");
  src: url("/fonts/prata/Prata-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/prata/Prata-Regular.woff") format("woff"), url("/fonts/prata/Prata-Regular.woff2") format("woff2"), url("/fonts/prata/Prata-Regular.ttf") format("truetype"), url("/fonts/prata/Prata-Regular.svg#Prata-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato-Light";
  src: local("Lato Light"), local("Lato-Light"), local("lato-thin"), url("/fonts/lato/Lato-Light.eot");
  src: url("/fonts/lato/Lato-Light.eot?#iefix") format("embedded-opentype"), url("/fonts/lato/Lato-Light.woff") format("woff"), url("/fonts/lato/Lato-Light.woff2") format("woff2"), url("/fonts/lato/Lato-Light.ttf") format("truetype"), url("/fonts/lato/Lato-Light.svg#Lato-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), local("lato"), url("/fonts/lato/Lato-Regular.eot");
  src: url("/fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/lato/Lato-Regular.woff") format("woff"), url("/fonts/lato/Lato-Regular.woff2") format("woff2"), url("/fonts/lato/Lato-Regular.ttf") format("truetype"), url("/fonts/lato/Lato-Regular.svg#Lato-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"), local("dancingscript"), url("/fonts/dancing-script/DancingScript-Regular.eot");
  src: url("/fonts/dancing-script/DancingScript-Regular.eot?#iefix") format("embedded-opentype"), url("/fonts/dancing-script/DancingScript-Regular.woff") format("woff"), url("/fonts/dancing-script/DancingScript-Regular.woff2") format("woff2"), url("/fonts/dancing-script/DancingScript-Regular.ttf") format("truetype"), url("/fonts/dancing-script/DancingScript-Regular.svg#DancingScript-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "hea";
  src: url("/fonts/hea-icons//hea.eot?3g3rkq");
  src: url("/fonts/hea-icons//hea.eot?3g3rkq#iefix") format("embedded-opentype"), url("/fonts/hea-icons//hea.woff2?3g3rkq") format("woff2"), url("/fonts/hea-icons//hea.ttf?3g3rkq") format("truetype"), url("/fonts/hea-icons//hea.woff?3g3rkq") format("woff"), url("/fonts/hea-icons//hea.svg?3g3rkq#hea") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "hea" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e90f";
}

.icon-star-outline:before {
  content: "\e90d";
}

.icon-palette:before {
  content: "\e90e";
}

.icon-arrow-down:before {
  content: "\e90b";
}

.icon-arrow-right:before {
  content: "\e90c";
}

.icon-close:before {
  content: "\e90a";
}

.icon-burger-menu:before {
  content: "\e909";
}

.icon-check-outlined:before {
  content: "\e900";
}

.icon-chevron-left:before {
  content: "\e901";
}

.icon-chevron-right:before {
  content: "\e902";
}

.icon-calendar:before {
  content: "\e903";
}

.icon-heart:before {
  content: "\e904";
}

.icon-holding-heart:before {
  content: "\e905";
}

.icon-pinterest:before {
  content: "\e906";
}

.icon-instagram:before {
  content: "\e907";
}

.icon-facebook:before {
  content: "\e908";
}

.v-align-solo {
  @apply relative top-1/2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blurred {
  @apply blur-sm;
}

.header-fixed {
  @apply fixed top-0 left-0 w-full z-30 bg-background;
}
.header-fixed .icon-socials i {
  @apply lg:text-secondary;
}
.header-fixed + * {
  margin-top: 86px;
}

#agency .header-fixed + * + * {
  margin-top: 86px;
}

.background-overlay {
  @apply relative;
}
.background-overlay:after {
  content: "";
  @apply absolute left-0 top-0 w-full h-full z-[1];
  background-color: rgba(0, 0, 0, 0.2);
}
.background-overlay > * {
  @apply z-[2];
}

@screen xl {
  .header-fixed {
    @apply py-0 transition-all duration-200;
  }
  .header-fixed img {
    @apply w-auto py-4 h-[105px] transition-all duration-200;
  }
  .header-fixed + * {
    margin-top: 156px;
  }
  #agency .header-fixed + * + * {
    @apply mt-0;
  }
}
@layer components {
  .text-title {
    @apply font-regular text-5.5xl;
  }
  .text-title-2 {
    @apply font-regular text-4.5xl leading-[57px];
  }
  .text-title-3 {
    @apply font-regular text-3.5xl leading-10;
  }
  .text-title-3-ds {
    @apply font-tertiary-regular text-3.5xl;
  }
  .text-title-4 {
    @apply font-regular text-2.5xl leading-[35px];
  }
  .text-subtitle {
    @apply font-secondary-regular text-1.5xl;
  }
  .text-subtitle-prata {
    @apply font-regular text-1.5xl;
  }
  .text-subtitle-2 {
    @apply font-secondary-regular text-lg;
  }
  .text-subtitle-2-prata {
    @apply font-regular text-lg;
  }
  .text-body-big {
    @apply font-secondary-light text-2.5xl;
  }
  .text-body {
    @apply font-secondary-light text-lg;
  }
  .text-body-2 {
    @apply font-secondary-light text-base;
  }
  .text-body-3 {
    @apply font-secondary-regular text-base;
  }
  .text-body-4 {
    @apply font-secondary-regular text-sm;
  }
  .text-hint {
    @apply font-secondary-light text-xs;
  }
}
@layer components {
  .btn {
    @apply inline-flex items-center text-body-3 py-1.75 px-5 cursor-pointer appearance-none outline-none transition-colors duration-200 border border-transparent;
  }
  .btn-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .btn-primary:hover {
    @apply bg-primary-beta border-primary-beta;
  }
  .btn-primary-white {
    @apply border-white text-white;
  }
  .btn-primary-white:hover {
    @apply bg-primary-beta border-white;
  }
  .btn-secondary {
    @apply bg-secondary border-secondary text-white;
  }
  .btn-secondary:hover {
    @apply bg-secondary-beta border-secondary-beta;
  }
  .btn-secondary-white {
    @apply border-white text-white;
  }
  .btn-secondary-white:hover {
    @apply bg-secondary-beta border-white;
  }
  .btn-white {
    @apply bg-white border-white text-black;
  }
  .btn-white:hover {
    @apply bg-black border-black text-white;
  }
  .btn-black {
    @apply bg-black border-black text-white;
  }
  .btn-black:hover {
    @apply bg-white border-white text-black;
  }
  .btn-outline-primary {
    @apply border-primary text-primary;
  }
  .btn-outline-primary:hover {
    @apply bg-primary-beta border-primary-beta text-font-light;
  }
  .btn-outline-secondary {
    @apply border-secondary text-secondary;
  }
  .btn-outline-secondary:hover {
    @apply bg-secondary-beta border-secondary-beta text-font-light;
  }
  .btn-outline-black {
    @apply border-black text-black;
  }
  .btn-outline-black:hover {
    @apply bg-black text-white;
  }
  .btn-outline-white {
    @apply border-white text-white;
  }
  .btn-outline-white:hover {
    @apply bg-white text-black;
  }
  .btn-shadow {
    @apply shadow;
  }
  .btn-shadow.btn-primary {
    @apply shadow-primary-beta;
  }
  .btn-shadow.btn-secondary {
    @apply shadow-secondary-beta;
  }
  .btn-shadow.btn-white {
    @apply shadow-white;
  }
  .btn-shadow.btn-white:hover {
    @apply shadow-black;
  }
  .btn-shadow.btn-black {
    @apply shadow-black;
  }
  .btn-shadow.btn-black:hover {
    @apply shadow-white;
  }
  .btn-square {
    @apply rounded-lg;
  }
  .btn-icon i {
    @apply text-2xl w-6 leading-6 text-center relative left-3;
  }
  .btn-icon-left i {
    @apply -left-3;
  }
  .btn-icon-only {
    @apply px-2.5;
  }
  .btn-icon-only i {
    @apply left-0;
  }
  .btn-small {
    @apply py-2.25 px-5 text-body-4;
  }
  .btn-small.btn-icon i {
    @apply text-xl w-5 leading-5 left-1.5;
  }
  .btn-small.btn-icon-left i {
    @apply -left-1.5;
  }
  .btn-small.btn-icon-only {
    @apply px-2.75;
  }
  .btn-small.btn-icon-only i {
    @apply left-0;
  }
  .btn-hidden-mobile {
    @apply hidden;
  }
  @screen md {
    .btn-hidden-mobile {
      @apply inline-flex;
    }
  }
}
@layer components {
  .chips {
    @apply rounded-full border text-body cursor-pointer px-3 py-0.5 inline-flex items-center appearance-none outline-none transition-colors duration-200;
  }
  .chips-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .chips-primary:hover {
    @apply border-primary-beta;
  }
  .chips-primary.active, .chips-primary:focus {
    @apply bg-primary-beta border-primary-beta;
  }
  .chips-secondary {
    @apply bg-secondary border-secondary text-font-light;
  }
  .chips-secondary:hover {
    @apply border-secondary-beta;
  }
  .chips-secondary.active, .chips-secondary:focus {
    @apply bg-secondary-beta border-secondary-beta;
  }
  .chips-white {
    @apply bg-white border-white text-black;
  }
  .chips-white:hover {
    @apply border-black;
  }
  .chips-white.active, .chips-white:focus {
    @apply bg-black text-white border-black;
  }
  .chips-black {
    @apply bg-black border-black text-white;
  }
  .chips-black:hover {
    @apply border-white;
  }
  .chips-black.active, .chips-black:focus {
    @apply bg-white text-black border-white;
  }
  .chips-icon > *:not(i) {
    @apply pl-2;
  }
  .chips-icon i {
    @apply text-xl w-5 leading-5 text-center relative -left-0.5;
  }
  .chips-icon-right > *:not(i), .chips-icon-both > *:not(i) {
    @apply pr-2;
  }
  .chips-icon-right i:last-child, .chips-icon-both i:last-child {
    @apply left-0.5;
  }
  .chips-icon-only {
    @apply px-2.5;
  }
  .chips-icon-only i {
    @apply left-0;
  }
}
@layer components {
  select.custom-input {
    @apply appearance-none outline-none;
  }
  select.custom-input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  textarea.custom-input {
    @apply appearance-none outline-none;
  }
  textarea.custom-input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  textarea.custom-input {
    @apply leading-6;
  }
  .custom-input {
    @apply pt-1 py-0.5 leading-9 text-base text-black bg-white min-w-0 max-w-full border-b border-font-medium relative;
  }
  .custom-input[type=text] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=text]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input [type=text] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=text]::placeholder {
    @apply text-gray-400;
  }
  .custom-input [type=text]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input[type=number] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=number]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input [type=number] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=number]::placeholder {
    @apply text-gray-400;
  }
  .custom-input [type=number]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input[type=email] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=email]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input [type=email] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=email]::placeholder {
    @apply text-gray-400;
  }
  .custom-input [type=email]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input[type=password] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=password]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input [type=password] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=password]::placeholder {
    @apply text-gray-400;
  }
  .custom-input [type=password]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input[type=tel] {
    @apply appearance-none outline-none;
  }
  .custom-input[type=tel]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input [type=tel] {
    @apply appearance-none outline-none;
  }
  .custom-input [type=tel]::placeholder {
    @apply text-gray-400;
  }
  .custom-input [type=tel]:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input select {
    @apply appearance-none outline-none;
  }
  .custom-input select::placeholder {
    @apply text-gray-400;
  }
  .custom-input textarea {
    @apply appearance-none outline-none;
  }
  .custom-input textarea::placeholder {
    @apply text-gray-400;
  }
  .custom-input::placeholder {
    @apply text-gray-400;
  }
  .custom-input textarea {
    @apply leading-6;
  }
  .custom-input select {
    @apply bg-white w-full;
  }
  .custom-input select:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input select {
    @apply cursor-pointer w-full;
  }
  .custom-input select:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input.select-container i, .custom-input .select-container i {
    @apply absolute right-0 leading-9 w-9 text-black text-xl text-center rounded-lg pointer-events-none;
  }
  .custom-input.focus, .custom-input:focus {
    @apply border-gray-400;
  }
  .custom-input.error {
    @apply border-error;
  }
  .custom-input.disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input-color-primary {
    @apply text-primary border-primary;
  }
  .custom-input-color-primary [type=text] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=text]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=number] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=number]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=email] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=email]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=password] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=password]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary [type=tel] {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary [type=tel]::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary select {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary select::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary textarea {
    @apply border-primary text-primary-beta;
  }
  .custom-input-color-primary textarea::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary.focus, .custom-input-color-primary:focus {
    @apply border-primary-beta;
  }
  .custom-input-color-primary::placeholder {
    @apply text-primary;
  }
  .custom-input-color-primary.select-container i, .custom-input-color-primary .select-container i {
    @apply text-primary;
  }
  .custom-input-color-primary .custom-input-radio input, .custom-input-color-primary .custom-input-checkbox input {
    @apply border-primary;
  }
  .custom-input-color-primary .custom-input-radio input:checked:before, .custom-input-color-primary .custom-input-checkbox input:checked:before {
    content: "";
    @apply bg-primary;
  }
  .custom-input-color-secondary {
    @apply text-secondary border-secondary;
  }
  .custom-input-color-secondary [type=text] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=text]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=number] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=number]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=email] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=email]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=password] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=password]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary [type=tel] {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary [type=tel]::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary select {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary select::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary textarea {
    @apply border-secondary text-secondary-beta;
  }
  .custom-input-color-secondary textarea::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary.focus, .custom-input-color-secondary:focus {
    @apply border-secondary-beta;
  }
  .custom-input-color-secondary::placeholder {
    @apply text-secondary;
  }
  .custom-input-color-secondary.select-container i, .custom-input-color-secondary .select-container i {
    @apply text-secondary;
  }
  .custom-input-color-secondary .custom-input-radio input, .custom-input-color-secondary .custom-input-checkbox input {
    @apply border-secondary;
  }
  .custom-input-color-secondary .custom-input-radio input:checked:before, .custom-input-color-secondary .custom-input-checkbox input:checked:before {
    content: "";
    @apply bg-secondary;
  }
  .custom-input-bg-primary {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=text] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=text]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=number] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=number]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=email] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=email]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=password] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=password]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary [type=tel] {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary [type=tel]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary select {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary select::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary textarea {
    @apply bg-primary border-primary text-font-light;
  }
  .custom-input-bg-primary textarea::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary.focus, .custom-input-bg-primary:focus {
    @apply border-primary-beta;
  }
  .custom-input-bg-primary::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-primary.select-container i, .custom-input-bg-primary .select-container i {
    @apply text-font-light;
  }
  .custom-input-bg-secondary {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=text] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=text]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=number] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=number]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=email] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=email]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=password] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=password]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary [type=tel] {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary [type=tel]::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary select {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary select::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary textarea {
    @apply bg-secondary border-secondary text-font-light;
  }
  .custom-input-bg-secondary textarea::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary.focus, .custom-input-bg-secondary:focus {
    @apply border-secondary-beta;
  }
  .custom-input-bg-secondary::placeholder {
    @apply text-font-light;
  }
  .custom-input-bg-secondary.select-container i, .custom-input-bg-secondary .select-container i {
    @apply text-font-light;
  }
  .custom-input-small {
    @apply leading-7;
  }
  .custom-input-small.select-container i, .custom-input-small .select-container i {
    @apply leading-7 w-7 text-lg;
  }
  .custom-input-checkbox-group .invalid-feedback, .custom-input-radio-group .invalid-feedback {
    @apply basis-full;
  }
  .custom-input-radio, .custom-input-checkbox {
    @apply flex items-center;
  }
  .custom-input-radio input, .custom-input-checkbox input {
    @apply appearance-none w-4 h-4 border-2 border-black rounded-full relative mr-2 cursor-pointer;
  }
  .custom-input-radio input:checked:before, .custom-input-checkbox input:checked:before {
    content: "";
    @apply absolute w-2 h-2 bg-black left-0.5 top-0.5 rounded-full;
  }
  .custom-input-radio input:disabled, .custom-input-checkbox input:disabled {
    @apply bg-gray-200 border-gray-200 cursor-default;
  }
  .custom-input-radio label, .custom-input-checkbox label {
    @apply cursor-pointer;
  }
  .custom-input-checkbox input {
    @apply rounded-md;
  }
  .custom-input-checkbox input:checked:before {
    @apply rounded-md;
  }
}
@layer components {
  .modal {
    @apply fixed w-full h-full z-20 top-0 left-0;
  }
  .modal .modal-header {
    height: 50px;
  }
  .modal .modal-container {
    @apply bg-white h-full;
  }
  .modal .scrollable-content {
    @apply overflow-auto;
    max-height: calc(100vh - 50px);
  }
  .modal-overlay {
    @apply fixed w-full h-full z-10 top-0 left-0 hidden opacity-50 bg-black;
  }
  .modal-overlay:after {
    content: "";
    @apply w-full h-full top-0 left-0 backdrop-blur-sm;
  }
  .trigger-modal {
    @apply cursor-pointer;
  }
  @screen md {
    .modal {
      @apply h-auto left-1/2;
      width: 400px;
    }
    .modal .modal-container {
      @apply rounded-xl overflow-hidden;
    }
  }
}
@layer components {
  .slide-content-section .slide-content-container {
    @apply hidden;
  }
  .slide-content-section .slide-content-container.slide-content-open {
    @apply block;
  }
  .slide-content-section .slide-content-button {
    @apply cursor-pointer;
  }
  @screen md {
    .slide-content-section.slide-content-only-mobile .slide-content-container {
      @apply block;
    }
    .slide-content-section.slide-content-only-mobile .slide-content-button {
      @apply cursor-default;
    }
    .slide-content-section.slide-content-only-mobile .slide-content-button i {
      @apply hidden;
    }
  }
}
@layer components {
  .nav-animate {
    @apply overflow-hidden overflow-x-scroll w-full whitespace-nowrap;
  }
  .nav-animate::-webkit-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-webkit-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate::-ms-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-ms-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate::-moz-scrollbar-thumb {
    @apply bg-transparent rounded-none;
  }
  .nav-animate::-moz-scrollbar {
    @apply w-0 h-0;
  }
  .nav-animate.nav-tabs {
    @apply border-none;
  }
  .nav-animate.nav-full-width {
    display: table;
  }
  .nav-animate.nav-full-width li {
    display: table-cell;
  }
  .nav-animate .nav-item {
    @apply relative;
  }
  .nav-animate a, .nav-animate span {
    @apply inline-block;
  }
  .nav-animate .active-bar {
    @apply absolute left-0 z-10 -bottom-0.5 h-1 w-full bg-black;
  }
  .nav-animate .active-bar.right {
    @apply right-0;
  }
  .nav-animate .active-bar-vertical {
    @apply bottom-0 top-3 w-1 h-10;
  }
  .nav-animate .nav-item {
    @apply cursor-pointer;
  }
  #webkit-nav ul {
    @apply flex items-center relative;
    height: 50px;
  }
  #webkit-nav ul .active-bar {
    @apply h-1;
    bottom: -13px;
  }
  #webkit-nav.fixed {
    @apply py-0 left-0 w-screen bg-gray-100 z-10 border-b border-gray-300;
    top: 86px;
  }
}
@layer components {
  .show-more-section .show-more-button {
    @apply cursor-pointer hidden;
  }
  .show-more-section .show-more-button span:last-child {
    @apply hidden;
  }
  .show-more-section .show-more-container {
    @apply transition-all duration-300 overflow-hidden;
    max-height: 200px;
  }
  .show-more-section.opened .show-more-button span:first-child {
    @apply hidden;
  }
  .show-more-section.opened .show-more-button span:last-child {
    @apply block;
  }
  .show-more-section.opened .show-more-container {
    max-height: 9999px !important;
  }
}
@layer components {
  .owl-section .owl-carousel {
    @apply overflow-x-hidden;
  }
  .owl-section .owl-carousel .owl-stage, .owl-section .owl-carousel .owl-item {
    @apply flex;
  }
  .owl-section .owl-carousel .owl-stage img, .owl-section .owl-carousel .owl-item img {
    @apply w-auto;
  }
  .owl-section .owl-carousel .owl-stage picture.img-w-full img, .owl-section .owl-carousel .owl-item picture.img-w-full img {
    @apply w-full;
  }
  .owl-section .custom-owl-nav span {
    @apply flex justify-center items-center cursor-pointer text-xl transition-colors duration-200;
  }
  .owl-section .custom-owl-nav span.disabled {
    @apply text-gray-300 border-gray-300;
  }
  .owl-section .custom-owl-nav span:hover:not(.disabled) {
    @apply text-black;
  }
  .owl-section .owl-dots {
    @apply flex items-center gap-5 justify-center mt-6;
  }
  .owl-section .owl-dots button {
    @apply bg-white flex rounded-full h-2 w-2 transition-colors duration-200;
  }
  .owl-section .owl-dots button:focus {
    @apply outline-none;
  }
  .owl-section .owl-dots button.active {
    @apply bg-primary-beta;
  }
  .owl-section-shifted .owl-container {
    @apply pr-0;
  }
}
header {
  @apply py-4 xl:py-7.5;
}
header .site-menu {
  @apply fixed top-0 left-0 h-full w-full translate-x-full transition-transform duration-200;
}
header .site-menu.opened {
  @apply translate-x-0;
}

@screen xl {
  header .site-menu {
    @apply translate-x-0 relative h-auto w-auto;
  }
}
footer .email-banner {
  background-image: url("../../img/footer-bg.jpg");
  @apply bg-no-repeat bg-cover bg-bottom;
}

#home #home-title {
  background-image: url("../../img/home-title-bg.svg");
  @apply bg-no-repeat bg-cover bg-bottom;
}
#home #home-single-image + section {
  @apply pt-[20px];
}
@screen lg {
  #home #home-single-image + section {
    @apply pt-[60px];
  }
}

#projects .landing-overlay > div {
  background: linear-gradient(90deg, theme("colors.primary-beta") 0%, theme("colors.primary-beta") 50%, rgba(255, 255, 255, 0) 100%);
}

#contact-page .contact-form-title {
  @apply relative;
}
#contact-page .contact-form-title:after {
  content: "";
  @apply h-[1px] w-[38px] bg-primary -bottom-3 absolute left-0;
}

.block-testimonies .custom-border-design {
  @apply relative;
}
.block-testimonies .custom-border-design:after, .block-testimonies .custom-border-design:before {
  content: "";
  @apply absolute h-[70px] w-0.5 bg-white left-1/2 -ml-[1px];
}
.block-testimonies .custom-border-design:after {
  @apply -top-[120px];
}
.block-testimonies .custom-border-design:before {
  @apply -bottom-[120px];
}

.block-prestations {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 10%, theme("colors.background") 10%, theme("colors.background") 70%, rgba(255, 255, 255, 0) 70%);
}

@screen md {
  .block-prestations {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 17%, theme("colors.background") 17%, theme("colors.background") 70%, rgba(255, 255, 255, 0) 70%);
  }
}
.block-carousels .owl-section .owl-stage .owl-item {
  @apply h-[400px];
}
.block-carousels .owl-section .owl-stage .owl-item img, .block-carousels .owl-section .owl-stage .owl-item picture {
  @apply h-full;
}
.block-carousels .owl-section .owl-dots button {
  @apply bg-primary opacity-75;
}
.block-carousels .owl-section .owl-dots button.active {
  @apply bg-secondary;
}
@screen lg {
  .block-carousels .owl-section .owl-stage .owl-item {
    @apply justify-center;
  }
}
@screen xl {
  .block-carousels .owl-section .owl-stage {
    @apply h-[530px] items-center;
  }
  .block-carousels .owl-section .owl-stage .owl-item {
    @apply items-center scale-100 transition-all duration-300 h-[383px];
  }
  .block-carousels .owl-section .owl-stage .owl-item img, .block-carousels .owl-section .owl-stage .owl-item picture {
    @apply h-auto transition-all duration-300;
  }
  .block-carousels .owl-section .owl-stage .owl-item.active.center {
    @apply h-full justify-center;
  }
  .block-carousels .owl-section .owl-stage .owl-item.active.center picture, .block-carousels .owl-section .owl-stage .owl-item.active.center img {
    @apply w-[380px];
  }
}

@screen xl {
  #projects .block-carousels .owl-section .owl-stage .owl-item {
    @apply justify-end scale-125;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active {
    @apply justify-start;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active ~ .active {
    @apply justify-end;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active > * {
    @apply relative -left-20;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active.center {
    transform: scale(1.65);
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active.center > * {
    @apply left-0;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active.center picture, #projects .block-carousels .owl-section .owl-stage .owl-item.active.center img {
    @apply w-full object-contain;
  }
  #projects .block-carousels .owl-section .owl-stage .owl-item.active.center + .active > * {
    left: inherit;
    @apply -right-20;
  }
}

.block-detailed-prestations.custom-bg {
  background-image: url("../../img/detailed-prestation-bg.svg");
  @apply bg-no-repeat bg-cover bg-bottom;
}

.block-contact-widget {
  background-image: url("../../img/contact-widget-bg.svg");
  @apply bg-no-repeat bg-cover bg-center;
}